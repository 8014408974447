export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: string;
  DateTime: string;
  DateTimeISO: any;
  DeweyDecimal: any;
  Duration: any;
  EmailAddress: string;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPCPatent: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  LCCSubclass: any;
  Latitude: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: string;
  NonNegativeFloat: number;
  NonNegativeInt: number;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: null;
};

export type Absence = {
  __typename?: 'Absence';
  attachments: Array<Scalars['String']>;
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  employee: EmployeeAbsenceInformation;
  extension: Scalars['Boolean'];
  id: Scalars['ID'];
  type: AbsenceType;
};

export enum AbsenceType {
  BereavementLeave = 'BereavementLeave',
  DisabilityLeave = 'DisabilityLeave',
  DomesticCalamity = 'DomesticCalamity',
  PaidLeave = 'PaidLeave',
  UnpaidLeave = 'UnpaidLeave'
}

export type AbsencesQuery = {
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<AbsenceType>;
};

/** Fila de reporte de vacaciones acumuladas */
export type AccumulatedVacationReportRow = {
  __typename?: 'AccumulatedVacationReportRow';
  accumulated: Scalars['Float'];
  available: Scalars['Float'];
  employeeDateStart: Scalars['Date'];
  employeeFullName: Scalars['String'];
  employeeIdNumber: Scalars['String'];
  requested: Scalars['BigInt'];
};

export type AmountsEmployeeInput = {
  bonus: Scalars['NonNegativeFloat'];
  currency: Scalars['String'];
  currencyBonus: Scalars['String'];
  currencyFees: Scalars['String'];
  fees: Scalars['NonNegativeFloat'];
  salary: Scalars['NonNegativeFloat'];
};

export type Area = {
  __typename?: 'Area';
  id: Scalars['ID'];
  name: Scalars['String'];
  userLeader?: Maybe<UserLeaderInformation>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String'];
  user: AuthUser;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  email: Scalars['EmailAddress'];
  employee?: Maybe<AuthUserEmployeeInfo>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  slackUserId?: Maybe<Scalars['ID']>;
};

export type AuthUserEmployeeInfo = {
  __typename?: 'AuthUserEmployeeInfo';
  id: Scalars['ID'];
  idNumber: Scalars['NonEmptyString'];
};

/** Modelo beneficio */
export type Benefit = {
  __typename?: 'Benefit';
  comment: Scalars['String'];
  commentAdmin: Scalars['String'];
  commentLeader: Scalars['String'];
  count?: Maybe<Scalars['NonNegativeInt']>;
  createdAt: Scalars['DateTime'];
  dateEnd?: Maybe<Scalars['Date']>;
  dateStart?: Maybe<Scalars['Date']>;
  documents: Array<BenefitDocument>;
  emailLeader?: Maybe<Scalars['NonEmptyString']>;
  employee: Employee;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  leader?: Maybe<Employee>;
  leaderId?: Maybe<Scalars['ID']>;
  status: BenefitStatus;
  type: BenefitTypes;
  updatedAt: Scalars['DateTime'];
};

export type BenefitDocument = {
  __typename?: 'BenefitDocument';
  benefitId: Scalars['NonEmptyString'];
  createdAt: Scalars['DateTime'];
  documentId: Scalars['NonEmptyString'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BenefitRequestInput = {
  comment?: InputMaybe<Scalars['NonEmptyString']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  dateStart?: InputMaybe<Scalars['Date']>;
  emailLeader?: InputMaybe<Scalars['EmailAddress']>;
  files?: InputMaybe<Array<UploadFile>>;
  type: BenefitTypes;
};

/** Status de la solicitud de beneficio */
export enum BenefitStatus {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

/** Tipos de beneficios */
export enum BenefitTypes {
  /** Dia de la familia (1 vez cada seis meses) */
  FamilyDay = 'FAMILY_DAY',
  /** Compras Internacionales 3 por año */
  InternationalPurchases = 'INTERNATIONAL_PURCHASES',
  /** Dias de matrimonio, dos dias una única vez */
  MarriageDays = 'MARRIAGE_DAYS',
  /** Dia para mudanza, dos dias por año */
  MovingDayOff = 'MOVING_DAY_OFF',
  /** Auxilio Óptico, una vez al año */
  OpticalAid = 'OPTICAL_AID'
}

export type BirthdaysFields = {
  __typename?: 'BirthdaysFields';
  dateBirth: Scalars['Date'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
};

export type BuildCertificationInput = {
  certificationId: Scalars['ID'];
  dataFormat?: InputMaybe<DataFormat>;
  employeeId: Scalars['ID'];
};

export type BuildCertificationResponse = {
  __typename?: 'BuildCertificationResponse';
  data: Scalars['String'];
};

export type BuildDocumentInput = {
  dataFormat?: InputMaybe<DataFormat>;
  documentId: Scalars['ID'];
  employeeId: Scalars['ID'];
};

export type BuildDocumentResponse = {
  __typename?: 'BuildDocumentResponse';
  data: Scalars['String'];
};

export type Certification = {
  __typename?: 'Certification';
  contract: Contract;
  contractId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['NonEmptyString'];
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  showToInactive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type Contract = {
  __typename?: 'Contract';
  certifications: Array<Certification>;
  country: Country;
  countryId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['NonEmptyString'];
  documents?: Maybe<Scalars['JSON']>;
  fields: Array<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  type: ContractType;
  undefinedTerm: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export enum ContractType {
  Freelance = 'Freelance',
  Hybrid = 'Hybrid',
  Normal = 'Normal'
}

export type Country = {
  __typename?: 'Country';
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CreateAbsencePayload = {
  attachments?: InputMaybe<Array<Scalars['String']>>;
  count: Scalars['PositiveInt'];
  data?: InputMaybe<Scalars['JSON']>;
  dateEnd: Scalars['DateTime'];
  dateStart: Scalars['DateTime'];
  extension: Scalars['Boolean'];
  type: AbsenceType;
};

export type CreateAreaInput = {
  name: Scalars['NonEmptyString'];
  userIdLeader?: InputMaybe<Scalars['NonEmptyString']>;
};

export type CreateCertificationInput = {
  contractId: Scalars['ID'];
  name: Scalars['NonEmptyString'];
};

export type CreateContractInput = {
  documents?: InputMaybe<Scalars['JSON']>;
  fields?: InputMaybe<Array<Scalars['JSON']>>;
  name: Scalars['NonEmptyString'];
  type?: InputMaybe<ContractType>;
  undefinedTerm?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCountryInput = {
  name: Scalars['NonEmptyString'];
};

export type CreateCustomFieldInput = {
  name: Scalars['NonEmptyString'];
  type?: InputMaybe<Scalars['NonEmptyString']>;
};

export type CreateDocumentInput = {
  name: Scalars['NonEmptyString'];
  type?: InputMaybe<DocumentType>;
};

/** Input para crear un nuevo empleado en el sistema */
export type CreateEmployeeInput = {
  accountBank?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  areaId: Scalars['ID'];
  arl?: InputMaybe<Scalars['String']>;
  bonus?: InputMaybe<Scalars['NonNegativeFloat']>;
  celebrateBirthday?: InputMaybe<Scalars['Boolean']>;
  cellPhone?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  civilStatus?: InputMaybe<Scalars['String']>;
  compensationFund?: InputMaybe<Scalars['String']>;
  computerHardware?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  countryId: Scalars['ID'];
  countryLocation?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  custom?: InputMaybe<Scalars['JSON']>;
  dateBirth?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  dateFamily?: InputMaybe<Scalars['Date']>;
  dateStart: Scalars['Date'];
  entranceExams?: InputMaybe<Scalars['String']>;
  eps?: InputMaybe<Scalars['String']>;
  fees?: InputMaybe<Scalars['NonNegativeFloat']>;
  firstName: Scalars['NonEmptyString'];
  gender?: InputMaybe<Scalars['String']>;
  hasDoctorateDegree?: InputMaybe<Scalars['Boolean']>;
  hasElementarySchool?: InputMaybe<Scalars['Boolean']>;
  hasGraduateDegree?: InputMaybe<Scalars['Boolean']>;
  hasHighSchool?: InputMaybe<Scalars['Boolean']>;
  hasMastersDegree?: InputMaybe<Scalars['Boolean']>;
  hasUniversity?: InputMaybe<Scalars['Boolean']>;
  idNumber: Scalars['NonEmptyString'];
  idType: Scalars['NonEmptyString'];
  lastName: Scalars['NonEmptyString'];
  middleName: Scalars['String'];
  pensionFund?: InputMaybe<Scalars['String']>;
  personalEmail: Scalars['String'];
  phone: Scalars['String'];
  positionId: Scalars['ID'];
  salary?: InputMaybe<Scalars['NonNegativeFloat']>;
  secondLastName: Scalars['String'];
  severanceFund?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateBirth?: InputMaybe<Scalars['String']>;
  subAreaId: Scalars['ID'];
  whereStudy?: InputMaybe<Scalars['String']>;
  withdrawalReason?: InputMaybe<Scalars['String']>;
};

export type CreateIncomeData = {
  email: Scalars['EmailAddress'];
  name: Scalars['NonEmptyString'];
};

export type CreateJobOfferData = {
  contractId: Scalars['ID'];
  name: Scalars['NonEmptyString'];
};

export type CreatePositionPayload = {
  name: Scalars['NonEmptyString'];
};

export type CreateSubAreaInput = {
  name: Scalars['NonEmptyString'];
};

export type CreateUploadResult = {
  __typename?: 'CreateUploadResult';
  fields: Scalars['JSON'];
  filename: Scalars['String'];
  url: Scalars['String'];
};

/** Campos personalizados para empleados del sistema */
export type CustomField = {
  __typename?: 'CustomField';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  type: Scalars['NonEmptyString'];
  updatedAt: Scalars['DateTime'];
};

export enum DataFormat {
  Base64 = 'BASE64',
  Uri = 'URI'
}

/** Documento Digital */
export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['String'];
  fields: Array<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: DocumentType;
  updatedAt: Scalars['DateTime'];
};

export enum DocumentType {
  Employees = 'Employees',
  JobOffers = 'JobOffers'
}

export type DocusignPluginData = {
  __typename?: 'DocusignPluginData';
  dsJWTClientId: Scalars['NonEmptyString'];
  impersonatedUserGuid: Scalars['NonEmptyString'];
  privateKey: Scalars['NonEmptyString'];
  redirectURI?: Maybe<Scalars['String']>;
};

export type DocusignPluginDataInput = {
  dsJWTClientId: Scalars['NonEmptyString'];
  impersonatedUserGuid: Scalars['NonEmptyString'];
  privateKey: Scalars['NonEmptyString'];
};

export type EditCertificationData = {
  contractId: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  showToInactive: Scalars['Boolean'];
};

export type EditDocumentPayload = {
  fields?: InputMaybe<Array<Scalars['JSON']>>;
  name?: InputMaybe<Scalars['NonEmptyString']>;
  type?: InputMaybe<DocumentType>;
};

/** Input para modificar empleado en el sistema */
export type EditEmployeeInput = {
  accountBank?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  areaId: Scalars['ID'];
  arl?: InputMaybe<Scalars['String']>;
  celebrateBirthday?: InputMaybe<Scalars['Boolean']>;
  cellPhone?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  civilStatus?: InputMaybe<Scalars['String']>;
  compensationFund?: InputMaybe<Scalars['String']>;
  computerHardware?: InputMaybe<Scalars['String']>;
  contractId: Scalars['ID'];
  countryId: Scalars['ID'];
  countryLocation?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  custom?: InputMaybe<Scalars['JSON']>;
  dateBirth?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  dateFamily?: InputMaybe<Scalars['Date']>;
  dateStart: Scalars['Date'];
  entranceExams?: InputMaybe<Scalars['String']>;
  eps?: InputMaybe<Scalars['String']>;
  firstName: Scalars['NonEmptyString'];
  gender?: InputMaybe<Scalars['String']>;
  hasDoctorateDegree?: InputMaybe<Scalars['Boolean']>;
  hasElementarySchool?: InputMaybe<Scalars['Boolean']>;
  hasGraduateDegree?: InputMaybe<Scalars['Boolean']>;
  hasHighSchool?: InputMaybe<Scalars['Boolean']>;
  hasMastersDegree?: InputMaybe<Scalars['Boolean']>;
  hasUniversity?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idNumber: Scalars['NonEmptyString'];
  idType: Scalars['NonEmptyString'];
  lastName: Scalars['NonEmptyString'];
  middleName: Scalars['String'];
  pensionFund?: InputMaybe<Scalars['String']>;
  personalEmail: Scalars['String'];
  phone: Scalars['String'];
  positionId: Scalars['ID'];
  secondLastName: Scalars['String'];
  severanceFund?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateBirth?: InputMaybe<Scalars['String']>;
  subAreaId: Scalars['ID'];
  whereStudy?: InputMaybe<Scalars['String']>;
  withdrawalReason?: InputMaybe<Scalars['String']>;
};

/** Modelo Empleado */
export type Employee = {
  __typename?: 'Employee';
  accountBank?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  affidavit?: Maybe<Scalars['Boolean']>;
  area: Area;
  areaId: Scalars['ID'];
  arl?: Maybe<Scalars['String']>;
  bonus: Scalars['NonNegativeFloat'];
  bonusInLetters: Scalars['String'];
  celebrateBirthday: Scalars['Boolean'];
  cellPhone?: Maybe<Scalars['String']>;
  certificationsAvailables: Array<Certification>;
  city?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  compensationFund: Scalars['String'];
  computerHardware: Scalars['String'];
  contract: Contract;
  contractId: Scalars['ID'];
  country: Country;
  countryId: Scalars['ID'];
  countryLocation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyBonus: Scalars['String'];
  currencyFees: Scalars['String'];
  custom: Scalars['JSON'];
  dateBirth?: Maybe<Scalars['Date']>;
  dateEnd?: Maybe<Scalars['Date']>;
  dateFamily?: Maybe<Scalars['Date']>;
  dateStart: Scalars['Date'];
  dateStartInText: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  entranceExams: Scalars['String'];
  eps?: Maybe<Scalars['String']>;
  fees: Scalars['NonNegativeFloat'];
  firstName: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender: Scalars['String'];
  hasDoctorateDegree?: Maybe<Scalars['Boolean']>;
  hasElementarySchool?: Maybe<Scalars['Boolean']>;
  hasGraduateDegree?: Maybe<Scalars['Boolean']>;
  hasHighSchool?: Maybe<Scalars['Boolean']>;
  hasMastersDegree?: Maybe<Scalars['Boolean']>;
  hasUniversity?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idNumber: Scalars['String'];
  idType: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  pensionFund?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position: Position;
  positionId: Scalars['ID'];
  salary: Scalars['NonNegativeFloat'];
  salaryInLetters: Scalars['String'];
  secondLastName: Scalars['String'];
  severanceFund: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stateBirth?: Maybe<Scalars['String']>;
  subArea: SubArea;
  subAreaId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
  vacationInfo: EmployeeVacation;
  vacations: Array<Vacation>;
  whereStudy?: Maybe<Scalars['String']>;
  withdrawalReason: Scalars['String'];
};

export type EmployeeAbsenceInformation = {
  __typename?: 'EmployeeAbsenceInformation';
  fullName: Scalars['String'];
  id: Scalars['ID'];
};

/** Employee vacations info */
export type EmployeeVacation = {
  __typename?: 'EmployeeVacation';
  accumulated: Scalars['Float'];
  available: Scalars['Float'];
  id: Scalars['ID'];
  idNumber: Scalars['String'];
  requested: Scalars['Int'];
};

export type EmployeeVacationInformation = {
  __typename?: 'EmployeeVacationInformation';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
};

/** Payload de la consulta de empleados */
export type EmployeesInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type FieldDocumentValue = {
  Name: Scalars['NonEmptyString'];
  Value: Scalars['NonEmptyString'];
};

/** Campos editables por empleado */
export type FieldsByEmployee = {
  __typename?: 'FieldsByEmployee';
  accountBank?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  arl?: Maybe<Scalars['String']>;
  celebrateBirthday?: Maybe<Scalars['Boolean']>;
  cellPhone?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  civilStatus?: Maybe<Scalars['String']>;
  compensationFund?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  custom?: Maybe<Scalars['JSON']>;
  dateBirth?: Maybe<Scalars['Date']>;
  dateFamily?: Maybe<Scalars['Date']>;
  entranceExams?: Maybe<Scalars['String']>;
  eps?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasDoctorateDegree?: Maybe<Scalars['Boolean']>;
  hasElementarySchool?: Maybe<Scalars['Boolean']>;
  hasGraduateDegree?: Maybe<Scalars['Boolean']>;
  hasHighSchool?: Maybe<Scalars['Boolean']>;
  hasMastersDegree?: Maybe<Scalars['Boolean']>;
  hasUniversity?: Maybe<Scalars['Boolean']>;
  idNumber?: Maybe<Scalars['String']>;
  idType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  pensionFund?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  positionName?: Maybe<Scalars['String']>;
  secondLastName?: Maybe<Scalars['String']>;
  severanceFund?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateBirth?: Maybe<Scalars['String']>;
  whereStudy?: Maybe<Scalars['String']>;
};

export type FieldsByEmployeeInput = {
  accountBank?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  arl?: InputMaybe<Scalars['String']>;
  celebrateBirthday?: InputMaybe<Scalars['Boolean']>;
  cellPhone?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  civilStatus?: InputMaybe<Scalars['String']>;
  compensationFund?: InputMaybe<Scalars['String']>;
  countryLocation?: InputMaybe<Scalars['String']>;
  custom?: InputMaybe<Scalars['JSON']>;
  dateBirth?: InputMaybe<Scalars['Date']>;
  dateFamily?: InputMaybe<Scalars['Date']>;
  entranceExams?: InputMaybe<Scalars['String']>;
  eps?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hasDoctorateDegree?: InputMaybe<Scalars['Boolean']>;
  hasElementarySchool?: InputMaybe<Scalars['Boolean']>;
  hasGraduateDegree?: InputMaybe<Scalars['Boolean']>;
  hasHighSchool?: InputMaybe<Scalars['Boolean']>;
  hasMastersDegree?: InputMaybe<Scalars['Boolean']>;
  hasUniversity?: InputMaybe<Scalars['Boolean']>;
  pensionFund?: InputMaybe<Scalars['String']>;
  personalEmail?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  severanceFund?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stateBirth?: InputMaybe<Scalars['String']>;
  whereStudy?: InputMaybe<Scalars['String']>;
};

/** Día feriado */
export type Holiday = {
  __typename?: 'Holiday';
  date: Scalars['Date'];
  name: Scalars['String'];
};

export type Income = {
  __typename?: 'Income';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  folderId: Scalars['String'];
  id: Scalars['ID'];
  jobOffer?: Maybe<JobOffer>;
  jobOfferId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  step: IncomeStep;
  updatedAt: Scalars['Date'];
};

export enum IncomeStep {
  CheckingContractData = 'CHECKING_CONTRACT_DATA',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  RequestContractData = 'REQUEST_CONTRACT_DATA',
  SignedJoboffer = 'SIGNED_JOBOFFER',
  WaitingForSignatureJoboffer = 'WAITING_FOR_SIGNATURE_JOBOFFER'
}

export type ItglobersSignDefaultPosition = {
  __typename?: 'ItglobersSignDefaultPosition';
  x?: Maybe<Scalars['PositiveFloat']>;
  y?: Maybe<Scalars['PositiveFloat']>;
};

export type ItglobersSignDefaultPositionInput = {
  x?: InputMaybe<Scalars['PositiveFloat']>;
  y?: InputMaybe<Scalars['PositiveFloat']>;
};

export type ItglobersSignPluginData = {
  __typename?: 'ItglobersSignPluginData';
  apiKey: Scalars['NonEmptyString'];
  clientId: Scalars['NonEmptyString'];
  defaultPosition: ItglobersSignDefaultPosition;
  endpoint: Scalars['NonEmptyString'];
};

export type ItglobersSignPluginDataInput = {
  apiKey: Scalars['NonEmptyString'];
  clientId: Scalars['NonEmptyString'];
  defaultPosition: ItglobersSignDefaultPositionInput;
  endpoint: Scalars['NonEmptyString'];
};

/** Oferta de laboral */
export type JobOffer = {
  __typename?: 'JobOffer';
  contract: Contract;
  contractId: Scalars['ID'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  document: Document;
  documentId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  updatedAt: Scalars['Date'];
};

/** Modelo de logs para registro de historicos del sistema */
export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  type: LogType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

/** Tipo de logs generados por el sistema */
export enum LogType {
  Error = 'Error',
  Info = 'Info',
  Warn = 'Warn'
}

export enum LogableType {
  Benefit = 'Benefit',
  Employee = 'Employee',
  Income = 'Income',
  Vacation = 'Vacation'
}

export type LogsInput = {
  query?: InputMaybe<LogsQueryInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type LogsQueryInput = {
  logableId?: InputMaybe<Scalars['ID']>;
  logableType?: InputMaybe<LogableType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Agregar dia feriado */
  appendHoliday: Holiday;
  auth: AuthResponse;
  buildCertification: BuildCertificationResponse;
  /** Devuelve un documento pdf en formato base64, de un documento con datos mapeados de un empleado */
  buildDocument: BuildDocumentResponse;
  calculateSalaryIncome: Scalars['JSON'];
  /** Reasignar usuario que aprueba la solicitud de vacaciones */
  changeApprovedUserIdInVacation?: Maybe<Scalars['Void']>;
  createAbsence: Absence;
  createArea?: Maybe<Scalars['Void']>;
  /** Crear solicitud de beneficio */
  createBenefitRequest?: Maybe<Scalars['Void']>;
  createCertification: Certification;
  createContract: Contract;
  /** Crear nuevo país */
  createCountry?: Maybe<Scalars['Void']>;
  /** Crear campo personalizado */
  createCustomField: CustomField;
  /** Crear documento digital */
  createDocument: Document;
  /** Create employee */
  createEmployee: Employee;
  createIncome: Income;
  createJobOffer: JobOffer;
  createPosition: Position;
  createSubArea?: Maybe<Scalars['Void']>;
  createUpload: CreateUploadResult;
  /** Crear solicitud de vacaciones */
  createVacation?: Maybe<Scalars['Void']>;
  deleteCertification?: Maybe<Scalars['Void']>;
  /** Borrar campo personalizado */
  deleteCustomField?: Maybe<Scalars['Void']>;
  /** Descargar documento base64 en formato PDF */
  downloadDocument: Scalars['NonEmptyString'];
  downloadURI: Scalars['URL'];
  editCertification: Certification;
  /** Modificar documento */
  editDocument?: Maybe<Scalars['Void']>;
  /** Edit employee */
  editEmployee: Employee;
  /** Crear una url firmada para subir archivos a al servidor */
  getSignedUrl: Scalars['NonEmptyString'];
  putSalaryEquationIncome?: Maybe<Scalars['Void']>;
  /** Put setting */
  putSetting?: Maybe<Setting>;
  /** Quitar dia feriado */
  removeHoliday?: Maybe<Scalars['Void']>;
  removeVacation?: Maybe<Scalars['Void']>;
  requestContractDataIncome?: Maybe<Scalars['Void']>;
  /**
   * Este método inicia el proceso masivo de envíos de comprobantes de pagos
   * a los diferentes empleados, por medio del procesamiento de un archivo zip contentivo [filename]
   */
  sendPaymentReceipts?: Maybe<SendMessageResult>;
  setDocusignPlugin?: Maybe<Scalars['Void']>;
  setItglobersSignPlugin?: Maybe<Scalars['Void']>;
  setSlackPlugin?: Maybe<Scalars['Void']>;
  setTRM: Trm;
  signatureJobOffer?: Maybe<Scalars['Void']>;
  softDeleteArea?: Maybe<Scalars['Void']>;
  softDeleteContract?: Maybe<Scalars['Void']>;
  /** Archivar país */
  softDeleteCountry?: Maybe<Scalars['Void']>;
  /** Archivar campo personalizado */
  softDeleteCustomField?: Maybe<Scalars['Void']>;
  /** Archivar contrato (borrado suave) */
  softDeleteDocument?: Maybe<Scalars['Void']>;
  softDeletePosition?: Maybe<Scalars['Void']>;
  softDeleteSubArea?: Maybe<Scalars['Void']>;
  submitContractDataIncome?: Maybe<Scalars['Void']>;
  /** Sincronizar archivo maestro */
  syncMasterSheet?: Maybe<Scalars['Void']>;
  syncUserIdSlackPlugin?: Maybe<Scalars['Void']>;
  /** Desvinculación de empleado */
  terminateEmployee?: Maybe<Scalars['Void']>;
  /** Activar o desactivar empleado */
  toggleEmployee?: Maybe<Scalars['Void']>;
  unlinkPlugin?: Maybe<Scalars['Void']>;
  /** Actualizar montos referentes a salarios, bonificaciones y honorarios */
  updateAmountsEmployee?: Maybe<Scalars['Void']>;
  updateArea?: Maybe<Scalars['Void']>;
  updateContract?: Maybe<Scalars['Void']>;
  updateContractDataFieldsIncome?: Maybe<Scalars['Void']>;
  /** Actualización de campos por parte del empleado */
  updateFieldsByEmployee?: Maybe<Scalars['Void']>;
  updatePosition: Position;
  /** Actualizar settings */
  updateSettings?: Maybe<Scalars['Void']>;
  /** Actualizar status de solicitud de beneficio */
  updateStatusBenefitRequest?: Maybe<Scalars['Void']>;
  /** Actualizar status de solicitud de vacaciones */
  updateVacation?: Maybe<Scalars['Void']>;
};


export type MutationAppendHolidayArgs = {
  countryId: Scalars['String'];
  date: Scalars['Date'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationAuthArgs = {
  token: Scalars['ID'];
};


export type MutationBuildCertificationArgs = {
  input: BuildCertificationInput;
};


export type MutationBuildDocumentArgs = {
  input?: InputMaybe<BuildDocumentInput>;
};


export type MutationCalculateSalaryIncomeArgs = {
  payload: Scalars['JSON'];
  salaryEquationID: Scalars['ID'];
};


export type MutationChangeApprovedUserIdInVacationArgs = {
  userApprovedId: Scalars['ID'];
  vacationId: Scalars['ID'];
};


export type MutationCreateAbsenceArgs = {
  employeeId: Scalars['ID'];
  payload: CreateAbsencePayload;
};


export type MutationCreateAreaArgs = {
  input: CreateAreaInput;
};


export type MutationCreateBenefitRequestArgs = {
  employeeId: Scalars['ID'];
  request: BenefitRequestInput;
};


export type MutationCreateCertificationArgs = {
  input: CreateCertificationInput;
};


export type MutationCreateContractArgs = {
  input: CreateContractInput;
};


export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};


export type MutationCreateCustomFieldArgs = {
  input: CreateCustomFieldInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateEmployeeArgs = {
  incomeId?: InputMaybe<Scalars['ID']>;
  input: CreateEmployeeInput;
};


export type MutationCreateIncomeArgs = {
  data: CreateIncomeData;
};


export type MutationCreateJobOfferArgs = {
  data: CreateJobOfferData;
};


export type MutationCreatePositionArgs = {
  payload: CreatePositionPayload;
};


export type MutationCreateSubAreaArgs = {
  input: CreateSubAreaInput;
};


export type MutationCreateUploadArgs = {
  mimeType: Scalars['NonEmptyString'];
};


export type MutationCreateVacationArgs = {
  employeeId: Scalars['ID'];
  request: VacationInput;
};


export type MutationDeleteCertificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomFieldArgs = {
  id: Scalars['ID'];
};


export type MutationDownloadDocumentArgs = {
  id: Scalars['ID'];
  values?: InputMaybe<Array<FieldDocumentValue>>;
};


export type MutationDownloadUriArgs = {
  filename: Scalars['NonEmptyString'];
};


export type MutationEditCertificationArgs = {
  data: EditCertificationData;
  id: Scalars['ID'];
};


export type MutationEditDocumentArgs = {
  id: Scalars['ID'];
  payload: EditDocumentPayload;
};


export type MutationEditEmployeeArgs = {
  input: EditEmployeeInput;
};


export type MutationGetSignedUrlArgs = {
  filename: Scalars['NonEmptyString'];
  type: Scalars['NonEmptyString'];
};


export type MutationPutSalaryEquationIncomeArgs = {
  equation: Scalars['NonEmptyString'];
  salaryEquationID: Scalars['ID'];
};


export type MutationPutSettingArgs = {
  key: Scalars['NonEmptyString'];
  value: Scalars['JSON'];
};


export type MutationRemoveHolidayArgs = {
  date: Scalars['Date'];
};


export type MutationRemoveVacationArgs = {
  vacationId: Scalars['ID'];
};


export type MutationRequestContractDataIncomeArgs = {
  incomeId: Scalars['ID'];
};


export type MutationSendPaymentReceiptsArgs = {
  filename: Scalars['NonEmptyString'];
  month?: InputMaybe<Scalars['NonEmptyString']>;
};


export type MutationSetDocusignPluginArgs = {
  data: DocusignPluginDataInput;
};


export type MutationSetItglobersSignPluginArgs = {
  data: ItglobersSignPluginDataInput;
};


export type MutationSetSlackPluginArgs = {
  code: Scalars['String'];
};


export type MutationSetTrmArgs = {
  trm: TrmInput;
};


export type MutationSignatureJobOfferArgs = {
  data: SignatureJobOfferData;
  incomeId: Scalars['ID'];
};


export type MutationSoftDeleteAreaArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeleteContractArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeleteCountryArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeleteCustomFieldArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeletePositionArgs = {
  id: Scalars['ID'];
};


export type MutationSoftDeleteSubAreaArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitContractDataIncomeArgs = {
  incomeId: Scalars['ID'];
  payload: Scalars['JSON'];
};


export type MutationTerminateEmployeeArgs = {
  dateEnd: Scalars['Date'];
  id: Scalars['ID'];
  withdrawalReason?: InputMaybe<Scalars['String']>;
};


export type MutationToggleEmployeeArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationUnlinkPluginArgs = {
  plugin: Plugin;
};


export type MutationUpdateAmountsEmployeeArgs = {
  id: Scalars['ID'];
  input: AmountsEmployeeInput;
};


export type MutationUpdateAreaArgs = {
  id: Scalars['ID'];
  input: CreateAreaInput;
};


export type MutationUpdateContractArgs = {
  data: UpdateContractData;
  id: Scalars['ID'];
};


export type MutationUpdateContractDataFieldsIncomeArgs = {
  fields: Scalars['JSON'];
  incomeId: Scalars['ID'];
};


export type MutationUpdateFieldsByEmployeeArgs = {
  data: FieldsByEmployeeInput;
  employeeId: Scalars['ID'];
};


export type MutationUpdatePositionArgs = {
  payload: UpdatePositionPayload;
};


export type MutationUpdateSettingsArgs = {
  settings: Array<UpdateSetting>;
};


export type MutationUpdateStatusBenefitRequestArgs = {
  benefitId: Scalars['ID'];
  comment?: InputMaybe<Scalars['NonEmptyString']>;
  status: BenefitStatus;
};


export type MutationUpdateVacationArgs = {
  comment?: InputMaybe<Scalars['String']>;
  status: VacationStatus;
  vacationId: Scalars['ID'];
};

export enum Plugin {
  Docusign = 'DOCUSIGN',
  ItglobersSign = 'ITGLOBERS_SIGN',
  Slack = 'SLACK'
}

/** Cargo del empleado */
export type Position = {
  __typename?: 'Position';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  absences: Array<Absence>;
  /** Reporte de acumulados */
  accumulatedVacationReport: Array<AccumulatedVacationReportRow>;
  /** Obtener el usuario que aprueba las vacaciones de un determinado empleado */
  approvedUserIdVacation?: Maybe<Scalars['NonEmptyString']>;
  areas: Array<Area>;
  /** Dias disponibles de un empleado */
  availableVacationDays: Scalars['Int'];
  /** Consultar solicitud de beneficios */
  benefit?: Maybe<Benefit>;
  /** Listar beneficios solicitadas */
  benefits: Array<Benefit>;
  /** Birthdays */
  birthdays: Array<BirthdaysFields>;
  certifications: Array<Certification>;
  certificationsForEmployee: Array<Certification>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  /** Países activos */
  countries: Array<Country>;
  /** Consultar los atributos relacionados a un país */
  country: Country;
  /** Todos los campos personalizados, activos */
  customFields: Array<CustomField>;
  /** Consultar documento */
  document: Document;
  /** Todos los documentos digitales */
  documents: Array<Document>;
  /** Consultar empleado */
  employee: Employee;
  /** Consultar empleados */
  employees: Array<Employee>;
  getActivePlugins: Array<Plugin>;
  getDocusignPlugin?: Maybe<DocusignPluginData>;
  /** Obtener empleado dado su userId, de inicio de sesion */
  getEmployeeByUserId?: Maybe<Employee>;
  /** Obtener campos de un empleado */
  getFieldsByEmployee: FieldsByEmployee;
  getItglobersSignPlugin?: Maybe<ItglobersSignPluginData>;
  getSalaryEquationIncome?: Maybe<Scalars['String']>;
  getSlackPlugin?: Maybe<SlackPluginData>;
  getUserAccount?: Maybe<UserAccount>;
  /** Listar los días  feriados del presente año */
  holidays: Array<Holiday>;
  income: Income;
  incomes: Array<Income>;
  jobOffer: JobOffer;
  jobOffers: Array<JobOffer>;
  /** Reporte de acumulados */
  loadHolidaysByCountry: Array<Holiday>;
  logs: Array<Log>;
  /** Campos de información que pueden ser llenados por el empleado */
  myInformationFields?: Maybe<Setting>;
  /** Ping para probar conexión, y estado de la sesión */
  ping?: Maybe<Scalars['Void']>;
  /** Cargos activos */
  positions: Array<Position>;
  /** Buscador de empleados */
  searchEmployees: Array<SearchEmployeeValue>;
  searchUser: Array<SearchUserValue>;
  /** Get setting */
  setting?: Maybe<Setting>;
  /** All settings */
  settings: Array<Setting>;
  subAreas: Array<SubArea>;
  trms: Array<Trm>;
  /** Consultar solicitud de vacaciones */
  vacation?: Maybe<Vacation>;
  /** Listar vacaciones solicitadas */
  vacations: Array<Vacation>;
  /** Dias laborables en entre dos fechas dadas */
  workingDaysBetween: Scalars['NonNegativeInt'];
};


export type QueryAbsencesArgs = {
  query?: InputMaybe<AbsencesQuery>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryApprovedUserIdVacationArgs = {
  employeeId: Scalars['ID'];
};


export type QueryAvailableVacationDaysArgs = {
  employeeId: Scalars['ID'];
};


export type QueryBenefitArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBenefitsArgs = {
  employeeId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<BenefitStatus>>;
  type?: InputMaybe<BenefitTypes>;
};


export type QueryCertificationsForEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  type?: InputMaybe<DocumentType>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  input?: InputMaybe<EmployeesInput>;
};


export type QueryGetEmployeeByUserIdArgs = {
  userId: Scalars['ID'];
};


export type QueryGetFieldsByEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type QueryGetSalaryEquationIncomeArgs = {
  salaryEquationID: Scalars['ID'];
};


export type QueryGetUserAccountArgs = {
  accountId: Scalars['EmailAddress'];
};


export type QueryHolidaysArgs = {
  countryId: Scalars['String'];
};


export type QueryIncomeArgs = {
  id: Scalars['ID'];
};


export type QueryJobOfferArgs = {
  id: Scalars['ID'];
};


export type QueryLoadHolidaysByCountryArgs = {
  countryId: Scalars['String'];
  forceReload?: InputMaybe<Scalars['Boolean']>;
  year: Scalars['String'];
};


export type QueryLogsArgs = {
  input: LogsInput;
};


export type QuerySearchEmployeesArgs = {
  payload?: InputMaybe<SearchEmployeePayload>;
};


export type QuerySearchUserArgs = {
  payload: SearchUserPayload;
};


export type QuerySettingArgs = {
  key: Scalars['String'];
};


export type QuerySettingsArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryVacationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryVacationsArgs = {
  payload: VacationsPayload;
};


export type QueryWorkingDaysBetweenArgs = {
  countryId: Scalars['String'];
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export enum Role {
  Admin = 'Admin',
  Develop = 'Develop',
  Disability = 'Disability',
  Employee = 'Employee',
  Leader = 'Leader',
  Super = 'Super',
  Vacations = 'Vacations'
}

/** Busqueda de empleados */
export type SearchEmployeePayload = {
  roles?: InputMaybe<Array<Role>>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type SearchEmployeeValue = {
  __typename?: 'SearchEmployeeValue';
  email: Scalars['String'];
  employeeId: Scalars['ID'];
  fullName: Scalars['String'];
  userId: Scalars['ID'];
};

/** Busqueda de usuarios */
export type SearchUserPayload = {
  roles?: InputMaybe<Array<Role>>;
  search?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type SearchUserValue = {
  __typename?: 'SearchUserValue';
  email: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type SendMessageResult = {
  __typename?: 'SendMessageResult';
  MD5OfMessageAttributes?: Maybe<Scalars['String']>;
  MD5OfMessageBody?: Maybe<Scalars['String']>;
  MD5OfMessageSystemAttributes?: Maybe<Scalars['String']>;
  MessageId?: Maybe<Scalars['String']>;
  SequenceNumber?: Maybe<Scalars['String']>;
};

/** Setting */
export type Setting = {
  __typename?: 'Setting';
  key: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type SignatureJobOfferData = {
  jobOfferId: Scalars['ID'];
  jobOfferValues: Array<Scalars['JSON']>;
};

export type SlackPluginData = {
  __typename?: 'SlackPluginData';
  clientId: Scalars['String'];
  redirectURI: Scalars['String'];
  scope: Array<Scalars['String']>;
  team?: Maybe<SlackTeam>;
};

export type SlackTeam = {
  __typename?: 'SlackTeam';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SubArea = {
  __typename?: 'SubArea';
  createdAt: Scalars['DateTime'];
  employees: Array<Employee>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userLeader?: Maybe<UserLeaderInformation>;
};

export type Trm = {
  __typename?: 'TRM';
  id: Scalars['ID'];
  trm: Scalars['Float'];
};

export type TrmInput = {
  id: Scalars['ID'];
  trm: Scalars['Float'];
};

export type UpdateContractData = {
  documents?: InputMaybe<Scalars['JSON']>;
  fields?: InputMaybe<Array<Scalars['JSON']>>;
  name: Scalars['NonEmptyString'];
  type?: InputMaybe<ContractType>;
  undefinedTerm?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePositionPayload = {
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
};

export type UpdateSetting = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type UploadFile = {
  filename: Scalars['NonEmptyString'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  email: Scalars['EmailAddress'];
  id: Scalars['ID'];
  roles: Array<Role>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['ID'];
  primaryEmail: Scalars['EmailAddress'];
};

export type UserApprovedInformation = {
  __typename?: 'UserApprovedInformation';
  employeeId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type UserLeaderInformation = {
  __typename?: 'UserLeaderInformation';
  employeeId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  userId: Scalars['ID'];
};

/** Modelo vacaciones */
export type Vacation = {
  __typename?: 'Vacation';
  count: Scalars['NonNegativeInt'];
  createdAt: Scalars['DateTime'];
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
  early: Scalars['NonNegativeInt'];
  employee: EmployeeVacationInformation;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  logs: Array<Log>;
  status: VacationStatus;
  type: VacationTypes;
  updatedAt: Scalars['DateTime'];
  userApproved: UserApprovedInformation;
};

export type VacationInput = {
  comment?: InputMaybe<Scalars['String']>;
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
  type: VacationTypes;
  userApprovedId?: InputMaybe<Scalars['ID']>;
};

/** Status de la solicitud de vacaciones */
export enum VacationStatus {
  Approved = 'Approved',
  Created = 'Created',
  Denied = 'Denied',
  Pending = 'Pending'
}

/** Tipos de vacaciones */
export enum VacationTypes {
  /** @deprecated No longer supported */
  Accumulated = 'Accumulated',
  Paid = 'Paid',
  Taken = 'Taken'
}

/** Payload de la consulta de vacaciones */
export type VacationsPayload = {
  dateStart?: InputMaybe<Scalars['Date']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<VacationStatus>>;
  take?: InputMaybe<Scalars['Int']>;
  userApprovedId?: InputMaybe<Scalars['ID']>;
};
